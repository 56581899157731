import React from 'react';
import { RouteFormatter } from '../routes';
import lindyImg from '../assets/img/about-lindy.jpeg';
import oliviaImg from '../assets/img/about-olivia.jpg';
import wynonaImg from '../assets/img/about-wynona.jpg';
import danetteImg from '../assets/img/about-danette.jpg';
import shannonImg from '../assets/img/about-shannon.jpg';
import SvgSeoOk from 'src/assets/svg/SvgSeoOk.svg';
import SvgSeoPoint from 'src/assets/svg/SvgSeoPoint.svg';
import SvgSeoStar from 'src/assets/svg/SvgSeoStar.svg';

export const seoListBread = (city) => [
    {
        name: 'All dog parks',
        url: RouteFormatter.listings({}),
    },
    {
        name: city?.state || '',
        url: RouteFormatter.listings({ first: city?.stateUrl }),
    },
    {
        name: city?.name || '',
        short: city?.stateShort || '',
    },
];

export const seoSubListBread = (city, title, cityUrl) => [
    {
        name: 'All dog parks',
        url: RouteFormatter.listings({}),
    },
    {
        name: city?.state || '',
        url: RouteFormatter.listings({ first: city?.stateUrl }),
    },
    {
        name: city?.name || '',
        url: cityUrl,
    },
    {
        name: title,
    },
];

export const how_works = [
    {
        title: 'Browse',
        text: 'Browse spots using map and filters (size, enclosure, etc).',
        icon: SvgSeoPoint,
    },
    {
        title: 'View and compare',
        text: 'See information, reviews and calendar for each listing.',
        icon: SvgSeoStar,
    },
    {
        title: 'Book a spot!',
        text: 'Easily book private play time in three simple clicks.',
        icon: SvgSeoOk,
    },
];

const spotUrl = (spotUrl) => RouteFormatter.listings({ first: spotUrl });
const SpotEle = ({ spot = {} }) => (
    <a href={spotUrl(spot?.spotUrl)} target="_blank" rel="noreferrer">
        {spot?.title}
    </a>
);
const FullFencedSearch = ({ city }) => (
    <a
        href={RouteFormatter.fencedListings(null, {
            query: {
                loc: `${city.name}, ${city.stateShort.toUpperCase() || city.stateUrl}`,
                lat: city.latitude || undefined,
                lng: city.longitude || undefined,
            },
        })}
        target="_blank"
        rel="noreferrer nofollow"
    >
        fully enclosed options
    </a>
);

export const seoFaqs = (city) => {
    const {
        name,
        reviews,
        swimmingSpot,
        trailSpot,
        trailSpotsCount,
        agilitySpot,
        agilitySpotsCount,
        beachSpot,
        dogPresentsCount,
        fieldSpot,
        fieldSpotsCount,
        fiveStarsPercentage,
        fullyFencedCount,
    } = city;

    return [
        {
            show: true,
            que: 'Are dog parks good for dogs?',
            ans: () => (
                <>
                    It is important for dogs to have off leash exercise to sniff and run. But the free and open nature of public dog parks has
                    drawbacks. There could be aggressive dogs, disease transmission and cleanliness issues. For this reason, many dog behaviorists
                    discourage visiting public dog parks and recommend Sniffspot's private dog parks. Sniffspot private dog parks only allow one guest
                    to book at a time and are not open to the public, which solves many of these issues.
                </>
            ),
        },
        {
            show: true,
            que: 'Are dog parks safe?',
            ans: () => (
                <>
                    While public dog parks have a number of safety issues, such as aggressive dogs, disease transmission and general cleanliness,
                    Sniffspot dog parks much safer than public dog parks. With Sniffspot all bookings are private for just you and dogs you bring with
                    you and we require all dogs to be vaccinated (or have equivalent titers). All Sniffspot locations are vetted and reviewed by
                    guests so you can find the perfect place for you and your dog.
                    {reviews.length ? ` ${fiveStarsPercentage}% of reviews on Sniffspot visits in the ${name} area are 5 stars.` : ''}
                </>
            ),
        },
        {
            show: true,
            que: 'How do I know if a dog park works for my dog?',
            ans: () => (
                <>
                    You can filter Sniffspot dog parks by fencing and distractions. If your dog doesn't have good recall, you can visit one of our{' '}
                    {fullyFencedCount > 0 ? `${fullyFencedCount} ` : ''}fully fenced dog parks in the area. If your dog is reactive to other dogs, you
                    can visit one of our {dogPresentsCount > 0 ? `${dogPresentsCount} ` : ''}off leash areas where there are no dogs audible or
                    visible nearby. You can also filter locations to avoid other domestic animals and people. We also recommend reading reviews and
                    reaching out to the host with any questions.
                </>
            ),
        },
        {
            show: !!beachSpot,
            que: `What is the best dog beach near ${name}?`,
            ans: () => (
                <>
                    The best Sniffspot dog beach near {name} is <SpotEle spot={beachSpot} />. At Sniffspot beaches dogs can generally be off leash.
                </>
            ),
        },
        {
            show: fullyFencedCount > 1,
            que: `Does ${name} have any fully fenced dog parks?`,
            ans: () => (
                <>
                    Sniffspot has {fullyFencedCount} fully fenced dog parks in or near {name}. Browse all <FullFencedSearch city={city} />.
                </>
            ),
        },
        {
            show: trailSpotsCount >= 2,
            que: `Where are good dog hiking trails near ${name}?`,
            ans: () => (
                <>
                    The {name} area has {trailSpotsCount} Sniffspot locations with private trails for you and your dog to walk or hike. Dogs can
                    generally be off leash on Sniffspot trails. The most popular trail is <SpotEle spot={trailSpot} />.
                </>
            ),
        },
        {
            show: agilitySpotsCount >= 2,
            que: `Are there dog agility courses for rent in ${name}?`,
            ans: () => (
                <>
                    Sniffspot has dog parks with agility equipment and/or dog obstacles in or near {name}. The most popular agility option is{' '}
                    <SpotEle spot={agilitySpot} />.
                </>
            ),
        },
        {
            show: !!swimmingSpot,
            que: `Where in ${name} can I find a pool for dog swimming?`,
            ans: () => (
                <>
                    Sniffspot has swimming options in the {name} area. The highest rated option is <SpotEle spot={swimmingSpot} />.
                </>
            ),
        },
        {
            show: fieldSpotsCount >= 2,
            que: `Can I rent a field for my dog to run near ${name}?`,
            ans: () => (
                <>
                    There are {fieldSpotsCount} Sniffspot dog parks that offer fields in or near {name} where dogs can be off leash. The most popular
                    field is <SpotEle spot={fieldSpot} />.
                </>
            ),
        },
    ];
};

export const seoSubsWaterFaqs = (city) => {
    const { name, swimmingSpot, swimmingSpotsCount, lowestPricedSwimmingSpot, highestPricedSwimmingSpot } = city;

    return [
        {
            que: 'Are dog water parks safe?',
            ans: () => (
                <>
                    While public dog parks have a number of safety issues, such as aggressive dogs, disease transmission and general cleanliness,
                    Sniffspot dog parks are much safer than public dog parks. With Sniffspot all bookings are private for just you and dogs you bring
                    with you and we require all dogs to be vaccinated (or have equivalent titers). All Sniffspot locations are vetted and reviewed by
                    guests so you can find the perfect place for you and your dog. 93% of reviews on Sniffspot visits are 5 stars.
                    <br />
                    If you are worried about whether you can have an enjoyable water experience, just know that it is possible to have peace of mind
                    when visiting dog waterparks by following basic safety guidelines. Some great ways to stay safe around water with your dog include
                    learning canine CPR and keep an eye on your dog at all times to ensure they are not distressed. You can learn more about safety
                    around water{' '}
                    <a href={RouteFormatter.helpCenter.waterSafe()} target="_blank" rel="noreferrer">
                        here
                    </a>
                    .
                </>
            ),
        },
        {
            que: `How can I find the best dog water parks in ${name}?`,
            ans: () => (
                <>
                    There are {swimmingSpotsCount} dog water parks in the {name} area. The most popular dog water park is{' '}
                    <SpotEle spot={swimmingSpot} />. You can browse all of our dog water parks{' '}
                    <a
                        href={RouteFormatter.waterListings(null, {
                            query: {
                                loc: `${city.name}, ${city.stateShort.toUpperCase() || city.stateUrl}`,
                                lat: city.latitude || undefined,
                                lng: city.longitude || undefined,
                            },
                        })}
                        target="_blank"
                        rel="noreferrer"
                    >
                        here
                    </a>
                    .
                </>
            ),
        },
        {
            que: 'How do I know if a dog water park works for my dog?',
            ans: () => (
                <>
                    Start by applying basic filters, such as fencing and distractions, to find a dog park that works for your dog. If your dog doesn't
                    have good recall, you can visit one of our fully fenced dog water parks. If your dog is reactive to other dogs, you can visit one
                    of our dog water parks where there are no dogs audible or visible nearby. Make sure to review the amenities of the spot to see
                    what kind of water features are offered to make sure it works for your dog. If your dog is not a strong swimmer, then perhaps
                    choose a dog water park with tamer water features rather than a river or beach, for instance. We also recommend reading reviews
                    and reaching out to the host with any questions, and of course, checking water park hours.
                </>
            ),
        },
        {
            que: 'What are the requirements to bring my dog to a Sniffspot water park?',
            ans: () => (
                <>
                    We require that all dogs have basic vaccinations or equivalent titers. We do not allow dogs with a bite history. We require that
                    guests sign our waiver and agree to follow our safety rules. Additionally, we recommend following our{' '}
                    <a href={RouteFormatter.helpCenter.waterSafe()} target="_blank" rel="noreferrer">
                        water safety guidelines
                    </a>
                    .
                </>
            ),
        },
        {
            que: 'Will there be other dogs at the Sniffspot water park at the same time as me?',
            ans: () => (
                <>
                    Sniffspot only allows one booking at a time and any host dogs are required to be kept away from the spot during visits. We also
                    enforce 30 min time buffers between bookings.
                </>
            ),
        },
        {
            que: 'How much do Sniffspot dog water parks cost?',
            ans: () => (
                <>
                    Dog water parks in the {name} area range from ${lowestPricedSwimmingSpot?.price} and ${highestPricedSwimmingSpot?.price}. The
                    least expensive dog water park is <SpotEle spot={lowestPricedSwimmingSpot} />. The most expensive dog water park is{' '}
                    <SpotEle spot={highestPricedSwimmingSpot} />. Each host sets their own price, so you can check the price on the specific Sniffspot
                    dog park listing.
                </>
            ),
        },
        {
            que: 'Can I have an event or party at a dog water park?',
            ans: () => (
                <>
                    If you are planning an amazing dog water park party, each Sniffspot water park has their own rules regarding special events. Many
                    water parks allow doggie birthday parties, training and other event types. Event organizers need to be responsible for their
                    guests and event organizers should coordinate with the host regarding their fun party.
                </>
            ),
        },
        {
            que: 'What are the coolest doggie water features available?',
            ans: () => (
                <>
                    Sniffspot dog water parks can have a variety of features, everything from swimming pools to rivers to beaches to hoses and kiddie
                    pools. If you are dreaming of a curtain of water, water tunnels, a ball pit pool, water slide ball pits, splash pools or other
                    cool features, you can definitely find amazing features at Sniffspot dog water parks. Each water park is different, so check the
                    details of the park to find the incredible dog water park of your dreams.
                </>
            ),
        },
    ];
};

export const seoSubsFencedFaqs = (city) => {
    const { name, fullyFencedSpot, fullyFencedCount, lowestPricedFullyFencedSpot, highestPricedFullyFencedSpot } = city;

    return [
        {
            que: 'Are fully fenced dog parks safe?',
            ans: () => (
                <>
                    While public dog parks have a number of safety issues, such as aggressive dogs, inattentive owners, disease transmission and
                    general cleanliness, Sniffspot dog parks are much safer than public dog parks. Human and animal safety is our #1 focus. Without a
                    private option, many dog owners are relegated to using athletic fields or tennis courts for their dogs, which are not private or
                    safe. With Sniffspot all bookings are private for just you and dogs you bring with you and we require all dogs to be vaccinated
                    (or have equivalent titers). All Sniffspot locations are vetted and reviewed by guests so you can find the perfect place for you
                    and your dog. 93% of reviews on Sniffspot visits are 5 stars. And with fully fenced Sniffspot parks, you can get the safety of a
                    fenced-in space for dogs, while still having plenty of space to run.
                </>
            ),
        },
        {
            que: `How can I find fully fenced dog parks in ${name}?`,
            ans: () => (
                <>
                    There are {fullyFencedCount} fully fenced dog parks in the {name} area. The most popular fully fenced park is{' '}
                    <SpotEle spot={fullyFencedSpot} />. You can browse all of our fully fenced dog parks{' '}
                    <a
                        href={RouteFormatter.fencedListings(null, {
                            query: {
                                loc: `${city.name}, ${city.stateShort.toUpperCase() || city.stateUrl}`,
                                lat: city.latitude || undefined,
                                lng: city.longitude || undefined,
                            },
                        })}
                        target="_blank"
                        rel="noreferrer"
                    >
                        here
                    </a>
                    .
                </>
            ),
        },
        {
            que: 'How do I know if a fully fenced dog park works for my dog?',
            ans: () => (
                <>
                    Many people looking for fully fenced parks have dogs that are not good with voice control. Start by applying basic filters, such
                    as fences height and distractions, to find a dog park that works for your dog. You can see attributes of fences such as height,
                    gaps and fencing type to find the perfect fenced-in space for dogs. You can filter by size to make sure you have plenty of space,
                    or a smaller space if you prefer a neighborhood park. You can browse on a map by park location to find one in your city or near
                    your city. If your dog is reactive to other dogs, you can visit one of our off-leash areas where there are no dogs audible or
                    visible near the space. Make sure to review the amenities of the spot to check features you want for your activity, such as fresh
                    water, trail features such as soft trails or walking trails, or even water fountains. If you prefer wood chips, many of our parks
                    have wood chips or indoor space if you prefer a beautiful park building.
                </>
            ),
        },
        {
            que: 'What are the requirements to bring my dog to a Sniffspot fully fenced dog park?',
            ans: () => (
                <>
                    We require that all dogs have basic vaccinations or equivalent titers. We do not permit use by pregnant dogs and we do not permit
                    dogs with a bite history. We require that guests sign our waiver and agree to follow our safety rules, such as staying within the
                    designated off-leash areas. Each park location also has their own set of rules that guests need to follow, such as limiting
                    excessive barking.
                </>
            ),
        },
        {
            que: 'Will there be other dogs at the Sniffspot fully fenced dog park at the same time as me?',
            ans: () => (
                <>
                    Sniffspot only allows one booking at a time and any host dogs are required to be kept away from the spot during visits. We also
                    enforce 30 min time buffers between bookings.
                </>
            ),
        },
        {
            que: 'How much do Sniffspot fully fenced dog parks cost?',
            ans: () => (
                <>
                    Fully fenced dog parks in the {name} area range from ${lowestPricedFullyFencedSpot?.price} and $
                    {highestPricedFullyFencedSpot?.price}. The least expensive fenced park is <SpotEle spot={lowestPricedFullyFencedSpot} />. The most
                    expensive fenced park is <SpotEle spot={highestPricedFullyFencedSpot} />. Each host sets their own price, so you can check the
                    price on the specific Sniffspot dog park listing.
                </>
            ),
        },
    ];
};

export const seoSubsAgilityFaqs = (city) => {
    const { name, agilitySpot, agilitySpotsCount, lowestPricedAgilitySpot, highestPricedAgilitySpot } = city;

    return [
        {
            que: 'Are dog agility parks safe?',
            ans: () => (
                <>
                    While public off-leash dog parks have a number of safety issues, such as aggressive dogs, inattentive owners, disease transmission
                    and general cleanliness, Sniffspot dog parks are much safer than city parks. Contrary to a typical dog park, with Sniffspot all
                    bookings are private for just you and dogs you bring with you and we require all dogs to be vaccinated (or have equivalent
                    titers). All Sniffspot locations are vetted and reviewed by guests so you can find the perfect safe place for you and your dog.
                    93% of reviews on Sniffspot visits are 5 stars. As always, ensuring you use park dog agility equipment safely is also key to an
                    enjoyable experience.
                </>
            ),
        },
        {
            que: `How can I find dog agility parks in ${name}?`,
            ans: () => (
                <>
                    There are {agilitySpotsCount} dog agility parks in the {name} area. The most popular dog agility park is{' '}
                    <SpotEle spot={agilitySpot} />. You can browse all of our dog agility parks{' '}
                    <a
                        href={RouteFormatter.agilityListings(null, {
                            query: {
                                loc: `${city.name}, ${city.stateShort.toUpperCase() || city.stateUrl}`,
                                lat: city.latitude || undefined,
                                lng: city.longitude || undefined,
                            },
                        })}
                        target="_blank"
                        rel="noreferrer"
                    >
                        here
                    </a>
                    .
                </>
            ),
        },
        {
            que: 'How do I know if a dog agility park works for my dog?',
            ans: () => (
                <>
                    Owners commonly wonder about whether a park works for their dog. Start by applying basic filters, such as fencing and
                    distractions, to find a fenced dog agility park that works for your dog. You can also filter on size to find a larger park or
                    neighborhood parks. If your dog doesn't have good recall, you can visit one of our fully fenced dog agility parks. If your dog is
                    reactive to other dogs, you can visit one of our dog agility parks where there are no dogs audible or visible nearby. You can read
                    the description of the spot to see the details of the set up, such as whether there is commercial grade park equipment, and other
                    amenities, such as park benches. Browse photos to get a sense for park design. We also recommend reading reviews and reaching out
                    to the host with any questions, and of course, checking agility park hours.
                </>
            ),
        },
        {
            que: 'What are the requirements to bring my dog to a Sniffspot agility park?',
            ans: () => (
                <>
                    We require that all dogs have basic vaccinations or equivalent titers. We do not allow dogs with a bite history. We require that
                    guests sign our waiver and agree to follow our safety rules. Treat all park equipment with respect and care.
                </>
            ),
        },
        {
            que: 'Will there be other dogs at the Sniffspot agility park at the same time as me?',
            ans: () => (
                <>
                    Sniffspot only allows one booking at a time and any host dogs are required to be kept away from the spot during visits. We also
                    enforce 30 min time buffers between bookings.
                </>
            ),
        },
        {
            que: 'How much do Sniffspot agility parks cost?',
            ans: () => (
                <>
                    Dog agility parks in the {name} area range from ${lowestPricedAgilitySpot?.price} and ${highestPricedAgilitySpot?.price}. The
                    least expensive dog agility park is <SpotEle spot={lowestPricedAgilitySpot} />. The most expensive dog agility park is{' '}
                    <SpotEle spot={highestPricedAgilitySpot} />. Each host sets their own price, so you can check the price on the specific Sniffspot
                    dog park listing.
                </>
            ),
        },
    ];
};

export const seoSubsFieldFaqs = (city) => {
    const { name, fieldSpot, fieldSpotsCount, lowestPricedFieldSpot, highestPricedFieldSpot } = city;

    return [
        {
            que: 'Are dog fields safe?',
            ans: () => (
                <>
                    If you are looking for an adventure, but also looking for safety, Sniffspot dog fields are a great option. While public dog parks
                    have a number of safety issues, such as aggressive dogs, distracted dog owners, disease transmission and general cleanliness,
                    Sniffspot dog parks are much safer than public dog parks. With Sniffspot all bookings are private space for just you and doggy
                    friends you bring with you and we require all dogs to be vaccinated (or have equivalent titers). Especially for training, privacy
                    is ideal. Contrary to public dog parks, all Sniffspot locations are vetted and reviewed by guests so you can find the perfect
                    place for you and your dog for off leash exercise or outdoor training. 93% of reviews on Sniffspot visits are 5 stars, while
                    public dog parks often do not even have verified reviews. All payment is done securely online by credit card in a few simple
                    clicks.
                </>
            ),
        },
        {
            que: `How can I find dog fields in ${name}?`,
            ans: () => (
                <>
                    There are {fieldSpotsCount} dog fields in the {name} area. The most popular dog field is <SpotEle spot={fieldSpot} />. You can
                    browse all of our dog fields{' '}
                    <a
                        href={RouteFormatter.fieldsListings(null, {
                            query: {
                                loc: `${city.name}, ${city.stateShort.toUpperCase() || city.stateUrl}`,
                                lat: city.latitude || undefined,
                                lng: city.longitude || undefined,
                            },
                        })}
                        target="_blank"
                        rel="noreferrer"
                    >
                        here
                    </a>
                    .
                </>
            ),
        },
        {
            que: 'How do I know if a dog field works for my dog?',
            ans: () => (
                <>
                    All park rental information is available online. Start by filtering parks by fencing, size and distractions, to find a private
                    spot that works for your dog. If your dog has poor recall, you can visit one of our secure fields. If your dog is reactive to
                    other dogs, you can visit one of our dog fields where there are no dogs audible or visible nearby. You can review the details of
                    the listing to make sure it works as your desired activity space, for example, safe dog exercise, dog training, a stress-free
                    adventure with your furry friend or even a puppy event. You can even check the park amenities chart for amenities such as indoor
                    training facilities, agility field, synthetic turf, exercise balls or field parking availability in advance. If inclement weather
                    is an issue or poor field conditions, you can reschedule your visit up to 2 hours in advance.
                </>
            ),
        },
        {
            que: 'What are the requirements to bring my dog to a Sniffspot dog field?',
            ans: () => (
                <>
                    We require that all four-legged friends have basic vaccinations or equivalent titers. We do not allow dogs with a bite history. We
                    require that guests sign our waiver and agree to follow our safety rules. Make sure to check host rules before visiting to ensure,
                    for instance, that you are following allowable sports activities rules and any special events rules.
                </>
            ),
        },
        {
            que: 'Will there be other dogs at the Sniffspot field at the same time as me?',
            ans: () => (
                <>
                    No. Facility rentals are for private play time and any host dogs are required to be kept away from the area during visits, so this
                    is perfect for a private adventure for you and your doggie friends, and perhaps a human friend. Hosts understand that many guests
                    have dogs in training and are nervous owners. So when you book a Sniffspot dog field you have the entire private spot to yourself.
                    We also enforce 30 min time buffers between time slots so you will not run into other guest dogs or a previous person when coming
                    or leaving in the field parking area. Some hosts may have multiple fields, but there is always a buffer between fields.
                </>
            ),
        },
        {
            que: 'How much do Sniffspot fields cost?',
            ans: () => (
                <>
                    Dog fields in the {name} area range from ${lowestPricedFieldSpot?.price} and ${highestPricedFieldSpot?.price}. The least expensive
                    dog field is <SpotEle spot={lowestPricedFieldSpot} />. The most expensive dog field is <SpotEle spot={highestPricedFieldSpot} />.
                    Each host sets their own price, so you can check the price on the specific Sniffspot dog park listing.
                </>
            ),
        },
    ];
};

export const seoSubsHikingFaqs = (city) => {
    const { name, trailSpot, trailSpotsCount, lowestPricedTrailSpot, highestPricedTrailSpot } = city;

    return [
        {
            que: 'Are dog hiking trails safe?',
            ans: () => (
                <>
                    While public dog parks have a number of safety issues, such as aggressive dogs, inattentive owners, disease transmission and
                    general cleanliness, Sniffspot dog parks are much safer than a city or county park. With a Sniffspot off-leash area all bookings
                    are private for just you and dogs you bring with you and we require all dogs to be vaccinated (or have equivalent titers). All
                    Sniffspot locations are vetted and reviewed by guests so you can find the perfect place for you and your dog. 93% of reviews on
                    Sniffspot visits are 5 stars. Our hiking trails tend to be outside the city, which lends to more privacy, but it is also important
                    to follow typical hiking safety precautions, such as potentially having trail maps.
                </>
            ),
        },
        {
            que: `How can I find dog hiking trails in ${name}?`,
            ans: () => (
                <>
                    There are {trailSpotsCount} dog hiking trails in the {name} area. The most popular dog hiking trail is{' '}
                    <SpotEle spot={trailSpot} />. You can browse all of our dog hiking trails{' '}
                    <a
                        href={RouteFormatter.hikingListings(null, {
                            query: {
                                loc: `${city.name}, ${city.stateShort.toUpperCase() || city.stateUrl}`,
                                lat: city.latitude || undefined,
                                lng: city.longitude || undefined,
                            },
                        })}
                        target="_blank"
                        rel="noreferrer"
                    >
                        here
                    </a>
                    .
                </>
            ),
        },
        {
            que: 'How do I know if a dog hiking trail works for my dog?',
            ans: () => (
                <>
                    Sniffspot provides the tools to find the ideal spot. Start by applying basic filters, such as fencing 6 feet tall and
                    distractions, to find a dog park with trails that works for your dog. You can also filter by size if you are looking for a certain
                    acre park, an expansive off-leash dog park. If your dog doesn't have good recall, you can visit one of our fully fenced dog hiking
                    trails. If your dog is reactive to other dogs, you can visit one of our dog hiking trails where there are no dogs audible or
                    visible nearby. Since you are looking for trails, make sure to check photos to see if it the spot has flat trails or gnarly
                    trails. You can also browse by specific amenities, if you are looking for a view or a beach, for instance, or other activities or
                    fun features. You can check whether the spot has off or on street parking, the regular park hours, whether it has paved trails or
                    a modern restroom.
                </>
            ),
        },
        {
            que: 'What are the requirements to bring my dog to a Sniffspot dog hiking trail?',
            ans: () => (
                <>
                    In order to hike the trails, we require that all dogs have basic vaccinations or equivalent titers. We do not permit dogs with a
                    bite history. We require that guests sign our waiver and agree to follow our safety rules.
                </>
            ),
        },
        {
            que: 'Will there be other dogs at the Sniffspot hiking trail at the same time as me?',
            ans: () => (
                <>
                    Sniffspot only allows one booking at a time and any host dogs are required to be kept away from the spot during visits. We also
                    enforce 30 min time buffers between bookings.
                </>
            ),
        },
        {
            que: 'How much do Sniffspot hiking trails cost?',
            ans: () => (
                <>
                    Dog hiking trails in the {name} area range from ${lowestPricedTrailSpot?.price} and ${highestPricedTrailSpot?.price}. The least
                    expensive dog hiking trail is <SpotEle spot={lowestPricedTrailSpot} />. The most expensive dog hiking trail is{' '}
                    <SpotEle spot={highestPricedTrailSpot} />. Each host sets their own price, so you can check the price on the specific Sniffspot
                    dog park listing.
                </>
            ),
        },
    ];
};

export const seoSubsSmallFaqs = (city) => {
    const { name, smallDogSpot, smallDogSpotsCount, lowestPricedSmallDogSpot, highestPricedSmallDogSpot } = city;

    return [
        {
            que: 'Are small dog parks safe?',
            ans: () => (
                <>
                    While public dog parks have a number of safety issues, such as aggressive dogs, disease transmission and general cleanliness,
                    Sniffspot dog parks are much safer than public dog parks. With Sniffspot all bookings are private for just you and dogs you bring
                    with you and we require all dogs to be vaccinated (or have equivalent titers). All Sniffspot locations are vetted and reviewed by
                    park users so you can find the perfect place for you and your dog. 93% of reviews on Sniffspot visits are 5 stars.
                </>
            ),
        },
        {
            que: `How can I find small dog parks in ${name}?`,
            ans: () => (
                <>
                    There are {smallDogSpotsCount} small dog parks in the {name} area. The most popular small dog park is{' '}
                    <SpotEle spot={smallDogSpot} />. You can browse all of our small dog parks{' '}
                    <a
                        href={RouteFormatter.smallDogListings(null, {
                            query: {
                                loc: `${city.name}, ${city.stateShort.toUpperCase() || city.stateUrl}`,
                                lat: city.latitude || undefined,
                                lng: city.longitude || undefined,
                            },
                        })}
                        target="_blank"
                        rel="noreferrer"
                    >
                        here
                    </a>
                    .
                </>
            ),
        },
        {
            que: 'How do I know if a small dog park works for my dog?',
            ans: () => (
                <>
                    Start by applying basic filters, such as fencing and distractions, to find a dog park that works for your furry friends. If your
                    dog doesn't respond to owner commands, you can visit one of our enclosed dog park areas. If your dog is reactive to other dogs,
                    you can visit one of our small dog park sites where there are no dogs audible or visible nearby. You can see any park amenities
                    provided, including a park bench, water for dogs or water features such as kiddie pool or water hose. If your dog loves water, you
                    can go to a park with water features, but if they don't love water, you can avoid them. We also recommend reading reviews to check
                    for park maintenance issues and reaching out to the host with any questions. And lastly, of course, check park hours. Many of our
                    parks are open from dawn until dusk.
                </>
            ),
        },
        {
            que: 'What are the requirements to bring my dog to a Sniffspot park?',
            ans: () => (
                <>
                    We require that all dogs have basic vaccinations or equivalent titers. We do not allow dogs with a bite history. We require that
                    owners sign our waiver and agree to follow our safety rules. We also require that pet owners follow safety protocols around water
                    features.
                </>
            ),
        },
        {
            que: 'Will there be other dogs at the Sniffspot park at the same time as me?',
            ans: () => (
                <>
                    Sniffspot only allows one booking at a time and any host dogs are required to be kept away from the spot during visits. We also
                    enforce 30 min time buffers between bookings.
                </>
            ),
        },
        {
            que: 'How much do Sniffspot small dog parks cost?',
            ans: () => (
                <>
                    Small dog parks in the {name} area range from ${lowestPricedSmallDogSpot?.price} and ${highestPricedSmallDogSpot?.price}. The
                    least expensive small dog park is <SpotEle spot={lowestPricedSmallDogSpot} />. The most expensive small dog park is{' '}
                    <SpotEle spot={highestPricedSmallDogSpot} />. Each host sets their own price, so you can check the price on the specific Sniffspot
                    dog park listing.
                </>
            ),
        },
        {
            que: 'Can I have an event or party at a small dog park?',
            ans: () => (
                <>
                    If you are planning an amazing small dog park party, each Sniffspot park has their own rules regarding special events. Many parks
                    allow doggie birthday parties, training, water parties and other event types. Event organizers need to be responsible for their
                    guests and event organizers should coordinate with the host regarding their fun party. Make sure the park has amenities you need
                    for your party, such as a park with lots of picnic tables with park benches.
                </>
            ),
        },
    ];
};

export const seoSubsIndoorFaqs = (city) => {
    const { name, indoorSpot, indoorSpotsCount, lowestPricedIndoorSpot, highestPricedIndoorSpot } = city;

    return [
        {
            que: 'Are indoor dog parks safe?',
            ans: () => (
                <>
                    While public dog parks have a number of safety issues, such as aggressive dogs, disease transmission and general cleanliness,
                    Sniffspot dog parks are much safer than public dog parks. With Sniffspot all bookings are private for just you and dogs you bring
                    with you and we require all dogs to be vaccinated (or have equivalent titers). All Sniffspot locations are vetted and reviewed by
                    park users so you can find the perfect safe place for you and your dog. 93% of reviews on Sniffspot visits are 5 stars. Since
                    indoor dog parks are completely enclosed, this adds another level of safety for your pup.
                </>
            ),
        },
        {
            que: 'How can I find indoor dog parks near me?',
            ans: () => (
                <>
                    There are {indoorSpotsCount} indoor dog parks in the {name} area. The most popular indoor dog park is{' '}
                    <SpotEle spot={indoorSpot} />. You can browse all of our indoor dog parks{' '}
                    <a
                        href={RouteFormatter.indoorListings(null, {
                            query: {
                                loc: `${city.name}, ${city.stateShort.toUpperCase() || city.stateUrl}`,
                                lat: city.latitude || undefined,
                                lng: city.longitude || undefined,
                            },
                        })}
                        target="_blank"
                        rel="noreferrer"
                    >
                        here
                    </a>
                    .
                </>
            ),
        },
        {
            que: 'How do I know if an indoor dog park works for my dog?',
            ans: () => (
                <>
                    Start by applying basic filters, such as distractions, to find a dog park that works for your furry friends. If your dog doesn't
                    respond to owner commands, indoor dog parks are a good choice due to their fully enclosed nature. If your dog is reactive to other
                    dogs, you can visit one of our indoor dog park sites where there are no dogs audible or visible nearby. You can see any park
                    amenities provided, including a park bench, water for dogs or water features such as kiddie pool or water hose. If your dog loves
                    water, you can go to a park with water features, but if they don't love water, you can avoid them. We also recommend reading
                    reviews to check for park maintenance issues and reaching out to the host with any questions. And lastly, of course, check park
                    hours. Many of our parks are open from dawn until dusk.
                </>
            ),
        },
        {
            que: 'What are the requirements to bring my dog to a Sniffspot park?',
            ans: () => (
                <>
                    We require that all dogs have basic vaccinations or equivalent titers. We do not allow dogs with a bite history. We require that
                    owners sign our waiver and agree to follow our safety rules. We also require that pet owners follow safety protocols around water
                    features.
                </>
            ),
        },
        {
            que: 'Will there be other dogs at the Sniffspot park at the same time as me?',
            ans: () => (
                <>
                    No. We only allow one booking at a time and any host dogs are required to be kept away from the area during visits. So when you
                    book a Sniffspot park you have the entire place to yourself and your dog-owning friends if you invite them. We also enforce 30 min
                    time buffers between bookings so you will not run into other park patrons when coming or leaving.
                </>
            ),
        },
        {
            que: 'How much do Sniffspot indoor dog parks cost?',
            ans: () => (
                <>
                    Indoor dog parks in the {name} area range from ${lowestPricedIndoorSpot?.price} and ${highestPricedIndoorSpot?.price}. The least
                    expensive indoor dog park is <SpotEle spot={lowestPricedIndoorSpot} />. The most expensive indoor dog park is{' '}
                    <SpotEle spot={highestPricedIndoorSpot} />. Each host sets their own price, so you can check the price on the specific Sniffspot
                    dog park listing.
                </>
            ),
        },
        {
            que: 'Can I have an event or party at an indoor dog park?',
            ans: () => (
                <>
                    If you are planning an amazing indoor dog park party, each Sniffspot park has their own rules regarding special events. Many parks
                    allow doggie birthday parties, training, water parties and other event types. Event organizers need to be responsible for their
                    guests and event organizers should coordinate with the host regarding their fun party. Make sure the park has amenities you need
                    for your party, such as a park with lots of picnic tables with park benches.
                </>
            ),
        },
    ];
};

export const seoSubsBeachFaqs = (city) => {
    const { name, beachSpot, beachSpotsCount, lowestPricedBeachSpot, highestPricedBeachSpot } = city;

    return [
        {
            que: 'Are dog beaches safe?',
            ans: () => (
                <>
                    While public dog parks have a number of safety issues, such as aggressive dogs, disease transmission and general cleanliness,
                    Sniffspot dog parks are much safer than public dog parks. With Sniffspot all bookings are private for just you and dogs you bring
                    with you and we require all dogs to be vaccinated (or have equivalent titers). All Sniffspot locations are vetted and reviewed by
                    park users so you can find the perfect safe place for you and your dog. 93% of reviews on Sniffspot visits are 5 stars.
                </>
            ),
        },
        {
            que: 'How can I find dog beaches near me?',
            ans: () => (
                <>
                    There are {beachSpotsCount} dog beaches in the {name} area. The most popular dog beach is <SpotEle spot={beachSpot} />. You can
                    browse all of our dog beaches{' '}
                    <a
                        href={RouteFormatter.beachListings(null, {
                            query: {
                                loc: `${city.name}, ${city.stateShort.toUpperCase() || city.stateUrl}`,
                                lat: city.latitude || undefined,
                                lng: city.longitude || undefined,
                            },
                        })}
                        target="_blank"
                        rel="noreferrer"
                    >
                        here
                    </a>
                    .
                </>
            ),
        },
        {
            que: 'How do I know if a dog beach works for my dog?',
            ans: () => (
                <>
                    Start by applying basic filters, such as fencing and distractions, to find a dog park that works for your furry friends. If your
                    dog doesn't respond to owner commands, you can visit one of our enclosed dog park areas. If your dog is reactive to other dogs,
                    you can visit one of our dog beach sites where there are no dogs audible or visible nearby. You can see any park amenities
                    provided, including a park bench, water for dogs or water features such as kiddie pool or water hose. If your dog loves water, you
                    can go to a park with water features, but if they don't love water, you can avoid them. We also recommend reading reviews to check
                    for park maintenance issues and reaching out to the host with any questions. And lastly, of course, check park hours. Many of our
                    parks are open from dawn until dusk.
                </>
            ),
        },
        {
            que: 'What are the requirements to bring my dog to a Sniffspot park?',
            ans: () => (
                <>
                    We require that all dogs have basic vaccinations or equivalent titers. We do not allow dogs with a bite history. We require that
                    owners sign our waiver and agree to follow our safety rules. We also require that pet owners follow safety protocols around water
                    features.
                </>
            ),
        },
        {
            que: 'Will there be other dogs at the Sniffspot park at the same time as me?',
            ans: () => (
                <>
                    No. We only allow one booking at a time and any host dogs are required to be kept away from the area during visits. So when you
                    book a Sniffspot park you have the entire place to yourself and your dog-owning friends if you invite them. We also enforce 30 min
                    time buffers between bookings so you will not run into other park patrons when coming or leaving.
                </>
            ),
        },
        {
            que: 'How much do Sniffspot dog beaches cost?',
            ans: () => (
                <>
                    Dog beaches in the {name} area range from ${lowestPricedBeachSpot?.price} and ${highestPricedBeachSpot?.price}. The least
                    expensive dog beach is <SpotEle spot={lowestPricedBeachSpot} />. The most expensive dog beach is{' '}
                    <SpotEle spot={highestPricedBeachSpot} />. Each host sets their own price, so you can check the price on the specific Sniffspot
                    dog park listing.
                </>
            ),
        },
        {
            que: 'Can I have an event or party at a dog beach?',
            ans: () => (
                <>
                    If you are planning an amazing dog beach party, each Sniffspot park has their own rules regarding special events. Many parks allow
                    doggie birthday parties, training, water parties and other event types. Event organizers need to be responsible for their guests
                    and event organizers should coordinate with the host regarding their fun party. Make sure the park has amenities you need for your
                    party, such as a park with lots of picnic tables with park benches.
                </>
            ),
        },
    ];
};

export const seoSubsPoolFaqs = (city) => {
    const { name, state, swimmingSpot, secondSwimmingSpot, swimmingSpotsCount } = city;

    return [
        {
            que: `Are Private Dog Swimming Pools in ${name} Safe?`,
            ans: () => (
                <>
                    Private dog swimming pools through Sniffspot offer {name} pet owners a safer alternative to public facilities. Unlike crowded
                    public pools in {state}, our bookings are completely private for you and your dogs.
                    <br />
                    <br />
                    We maintain high safety standards across {name} dog pools, requiring all dogs to have current vaccinations or titers. Local {name}{' '}
                    hosts are vetted, and with 93% of Sniffspot visits receiving 5-star reviews, you can feel confident about your dog swimming pool
                    rental. Learn more about our{' '}
                    <a href={RouteFormatter.helpCenter.waterSafe()} target="_blank" rel="noreferrer">
                        water safety guidelines here
                    </a>{' '}
                    for {name} and beyond before booking.
                </>
            ),
        },
        {
            que: `How Can I Find the Best Dog Swimming Pools in ${name}, ${state}? `,
            ans: () => (
                <>
                    {name} is home to {swimmingSpotsCount} private dog swimming pools. Local favorites include <SpotEle spot={swimmingSpot} /> and{' '}
                    <SpotEle spot={secondSwimmingSpot} />. Browse all {name} swimming pools for dogs above to find the perfect spot in your part of{' '}
                    {state}.
                </>
            ),
        },
        {
            que: `How Do I Know if a ${name} Swimming Pool Works for My Dog?`,
            ans: () => (
                <>
                    Every pool in {name}, {state} has unique features to suit different needs. Use our filters to find {name} locations with specific
                    amenities like heated water, shallow entry points, or extra secure fencing. For {state} dogs new to swimming, several {name} hosts
                    offer gentle introduction areas. Read local reviews from fellow {name} pet owners and reach out to hosts about their specific pool
                    features.
                </>
            ),
        },
        {
            que: `What Are the Requirements for Swimming Pool Visits in ${name}?`,
            ans: () => (
                <>
                    Like all {state} locations, our {name} hosts require current vaccinations or equivalent titers for visiting dogs. We don't permit
                    dogs with bite histories at any {name} dog swimming pools. All guests must sign our standard waiver and follow both Sniffspot's
                    safety guidelines and any additional rules set by {name} hosts.
                </>
            ),
        },
        {
            que: `Will We Have the ${name} Dog Pool to Ourselves?`,
            ans: () => (
                <>
                    Absolutely! Whether you're visiting a pool in {name} or a nearby city, your booking is completely private. We enforce 30-minute
                    buffers between guests, and host dogs are always kept separate during your pool time.
                </>
            ),
        },
        {
            que: `How Much Do ${name} Dog Swimming Pools Cost?`,
            ans: () => (
                <>
                    Pool rentals across {name} typically range from $5-25 per hour. Pricing varies based on each {name} host's specific offerings and
                    pool features.
                </>
            ),
        },
        {
            que: `Can I Host a Pool Party in ${name}?`,
            ans: () => (
                <>
                    Many {name}, {state} hosts welcome doggie pool parties! Whether you're planning a {state} breed meetup or a canine birthday
                    celebration, check with individual {name} hosts about their event policies and group rates. Event organizers should discuss
                    details directly with hosts and are responsible for all party guests.
                </>
            ),
        },
        {
            que: `What amenities do ${name} dog pools offer?`,
            ans: () => (
                <>
                    Our {name} dog swimming pools feature various amenities like temperature-controlled water, easy-access ramps, pool toys, and
                    drying stations. Some {state} locations include special features like pool steps, floating docks, or swimming assistance. Check
                    individual {name} dog pool listings for specific features and amenities.
                </>
            ),
        },
    ];
};

export const TRAINERS_LIST = [
    {
        img: danetteImg,
        name: 'Danette Johnston',
        text: () => (
            <>
                Owner -{' '}
                <a className="about-link-text" href="https://dogsdayoutseattle.com/" target="_blank" rel="noreferrer">
                    Dog's Day Out
                </a>
                , Ballard, WA. Certified Professional Dog Trainer (CPDT-KA). Licensed AKC CGC Evaluator NW Coordinator, Doggone Safe
            </>
        ),
    },
    {
        img: wynonaImg,
        name: 'Wynona Karbo',
        text: () => (
            <>
                Owner -{' '}
                <a className="about-link-text" href="https://ahimsadogtraining.com/" target="_blank" rel="noreferrer">
                    Ahimsa Dog Training
                </a>
                , Ballard, WA. Certified Professional Dog Trainer (CPDT-KA) Co-host, “It's Raining Cats and Dogs” on KIRO 97.3 FM
            </>
        ),
    },
    {
        img: shannonImg,
        name: 'Shannon Finch',
        text: () => (
            <>
                <a className="about-link-text" href="http://www.animalkindtraining.com/" target="_blank" rel="noreferrer">
                    AnimalKind Training
                </a>{' '}
                M.Ed. Humane Education Karen Pryor Academy. Certified Training Partner. Certified Tellington TTouch and TTEAM Practitioner
            </>
        ),
    },
    {
        img: lindyImg,
        name: 'Lindy Langum',
        text: () => (
            <>
                Founder - K9 Fun Club Staff Trainer -{' '}
                <a className="about-link-text" href="https://summitdogs.org/" target="_blank" rel="noreferrer">
                    Summit Assistance Dogs
                </a>{' '}
                Certified in Canine Studies (CSS), NW School of Canine Studies
            </>
        ),
    },
    {
        img: oliviaImg,
        name: 'Olivia Peterson, CCS',
        text: () => (
            <>
                Owner -{' '}
                <a className="about-link-text" href="http://soundconnectiondogtraining.com/" target="_blank" rel="noreferrer">
                    Sound Connection Dog Training
                </a>{' '}
                WSU Bachelors in Animal Science Business Management. Northwest School of Canine Studies (NWSCS) Certification
            </>
        ),
    },
];

export const sniffDesc =
    "Sniffspot provides the best experiences and fun for you and your dog. Our dog parks are designed for the ultimate dog exercise. Our private spaces help you minimize distractions or triggers and maximize time with your dog. We provide off leash enrichment - exploration and activities you can't get anywhere else; wear your dog out for days. We make it easy to have playdates. Socialize your pup in a controlled, private spot - it's great exercise and it's fun for you and your dog.";
